import React, { Component } from 'react'
//import Notifications from './Notifications'
import WeightList from '../weights/WeightList'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import { Redirect } from 'react-router-dom'
import moment from 'moment'
import { Line } from 'react-chartjs-2'

class Dashboard extends Component {
  render() {
    const {weights, auth} = this.props;
    if(!auth.uid) return <Redirect to='/signin' />

    if(weights && weights.length > 0) {
      var dateData = [];
      var weightData = [];

      weights.forEach(weight => {
        if(!weight.date) {
          weight.date = moment(weight.createdAt.toDate()).format('M/D/YY').toString();
          weight.bodyFatLb = (weight.weight * weight.bodyFatPerct / 100).toFixed(2);
          weight.muscleLb = (weight.weight * weight.musclePerct / 100).toFixed(2);
          
          dateData.push(weight.date);
          weightData.push(weight.weight);
        }
      });
      
      const data = {
        labels: dateData.reverse(),
        datasets: [
          {
            label: 'My Weight Trend',
            fill: false,
            lineTension: 0.1,
            backgroundColor: 'rgba(75,192,192,0.4)',
            borderColor: 'rgba(75,192,192,1)',
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBorderColor: 'rgba(75,192,192,1)',
            pointBackgroundColor: '#fff',
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: 'rgba(75,192,192,1)',
            pointHoverBorderColor: 'rgba(220,220,220,1)',
            pointHoverBorderWidth: 2,
            pointRadius: 1,
            pointHitRadius: 10,
            data: weightData.reverse()
          }
        ]
      }

      return (
        <div className="dashboard container">
          <div className="row">
            <div className="col s12 m12 l8">
              <WeightList weights={weights} />
            </div>
            {/* <div className="col s12 m5 offset-m1">
              <Notifications notifications={notifications} />
            </div> */}
          </div>
          <Line data={data} />
        </div>
      )
    } else {
      return (
        <div className="container center">
          <p>You have not added any weight details yet...</p>
          <a href="/newWeight">
            Add Weight
          </a>
        </div>
      )
    }
  }
}

const mapStateToProps = (state) => {
  return {
    weights: state.firestore.ordered.weights,
    auth: state.firebase.auth
    // notifications: state.firestore.ordered.notifications
  }
}

export default compose(
  connect(mapStateToProps),
  firestoreConnect(props => [
    { collection: 'weights', where: ["userId", "==", props.auth && props.auth.uid ? props.auth.uid : null], orderBy: ['createdAt','desc'] }
    // { collection: 'notifications', orderBy: ['time','desc'], limit: 3}
  ])
)(Dashboard)