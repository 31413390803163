import React, { Component } from 'react'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import { Redirect } from 'react-router-dom'
import moment from 'moment'
import { deleteWeight } from '../../store/actions/weightActions'

class WeightDetails extends Component {

  handleClick = () => {
    this.props.deleteWeight(this.props.match.params.id);
    this.props.history.push('/');
  }

  handleBack = () => {
    this.props.history.push('/');
  }
  render() {
    const { weight, auth } = this.props;
 
    if(!auth.uid) return <Redirect to='/signin' />
    
    if(weight) {
      return(
        <div className="container section weight-details">
          <div className="card">
            <div className="card-content">
              <div className="right">
                <button className="btn red" onClick={this.handleClick}>DELETE</button>
              </div>
              <span className="card-title">{ moment(weight.createdAt.toDate()).format('MMMM Do YYYY, h:mm a') }</span>
              <table className="highlight responsive-table">
                <thead>
                  <tr>
                      <th>Weight</th>
                      <th>Body Fat %</th>
                      <th>Total Body Water</th>
                      <th>Muscle %</th>
                      <th>Bone Mass</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{ weight.weight }</td>
                    <td>{ weight.bodyFatPerct }</td>
                    <td>{ weight.totalBodyWater }</td>
                    <td>{ weight.musclePerct }</td>
                    <td>{ weight.boneMass }</td>
                  </tr>
                </tbody>
              </table>
              <p className="flow-text">{ weight.note }</p>            
            </div>
            <div className="card-action grey lighten-4 grey-text">
              <button className="btn grey" onClick={this.handleBack}>Back</button>
              <div className="right">{ moment(weight.createdAt.toDate()).calendar() }</div>
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div className="container center">
          <p>loading...</p>
        </div>
      )
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  const id = ownProps.match.params.id;
  const weights = state.firestore.data.weights;
  const weight = weights ? weights[id] : null
  return {
    weight: weight,
    auth: state.firebase.auth
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    deleteWeight: (id) => { dispatch(deleteWeight(id)) }
  }
}

export default compose (
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect((state, ownProps) => [
    {collection: 'weights', doc: ownProps.match.params.id }
  ])
)(WeightDetails)