import React from 'react'
import moment from 'moment'
import { MDBDataTable } from 'mdbreact';

//import { Link } from 'react-router-dom'
//import WeightSummary from './WeightSummary'
//import { default as NumberFormat } from 'react-number-format';

const WeightList = ({weights}) => { 
  //console.log("weights", weights);
  weights.forEach(weight => {
    if(!weight.date) {
      weight.date = moment(weight.createdAt.toDate()).format('M/D/YY').toString();
      weight.bodyFatLb = (weight.weight * weight.bodyFatPerct / 100).toFixed(2);
      weight.muscleLb = (weight.weight * weight.musclePerct / 100).toFixed(2);
    }
  });

  const [datatable] = React.useState({
    columns: [
      {
        label: 'Date',
        field: 'date',
        sort: 'disabled',
        attributes: {
          'aria-controls': 'DataTable',
          'aria-label': 'Weight'
        }
      },
      {
        label: 'Weight',
        field: 'weight',
        sort: 'disabled'
      },
      {
        label: 'BF%',
        field: 'bodyFatPerct',
        sort: 'disabled'
      },
      {
        label: 'BFLb',
        field: 'bodyFatLb',
        sort: 'disabled'
      },
      {
        label: 'Mus%',
        field: 'musclePerct',
        sort: 'disabled'
      },
      {
        label: 'MusLb',
        field: 'muscleLb',
        sort: 'disabled'
      }
    ],
    rows: weights
  });

  return (
    <MDBDataTable
      data={datatable}
      striped
      small
      noBottomColumns
      entries={5}
      pagesAmount={5}
      searching={false}
      sortable={false}
      displayEntries={false}
    />
  );
}

export default WeightList