import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'

// Your web app's Firebase configuration
var firebaseConfig = {
  apiKey: "AIzaSyDHS43ojo0rol6bJvutabJ1HNzTOy0xulw",
  authDomain: "weightrend.firebaseapp.com",
  databaseURL: "https://weightrend.firebaseio.com",
  projectId: "weightrend",
  storageBucket: "weightrend.appspot.com",
  messagingSenderId: "965046241396",
  appId: "1:965046241396:web:9ebb8e8b9b672e9846e921",
  measurementId: "G-63YZJMD5NP"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.firestore().settings({});

export default firebase;