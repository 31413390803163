import authReducer from './authReducer'
import weightReducer from'./weightReducer'
import { combineReducers } from 'redux'
import { firestoreReducer } from 'redux-firestore'
import { firebaseReducer } from 'react-redux-firebase'

const rootReducer = combineReducers({
  auth: authReducer,
  weight: weightReducer,
  firestore: firestoreReducer,
  firebase: firebaseReducer
});

export default rootReducer