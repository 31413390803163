export const addWeight = (weight) => {
  return (dispatch, getState, {getFirebase, getFirestore}) => {
    // make async call to datatbase
    const firestore = getFirestore();
    const userId = getState().firebase.auth.uid;

    firestore.collection('weights').add({
      ...weight,
      userId: userId,
      createdAt: new Date()
    }).then(() => {
      dispatch({type: 'ADD_NEW_WEIGHT', weight});
    }).catch((err) => {
      dispatch({type: 'ADD_NEW_WEIGHT_ERROR', err});
    })
  }
};

export const deleteWeight = (id) => {
  return (dispatch, getState, {getFirebase, getFirestore}) => {
    // make async call to datatbase
    const firestore = getFirestore();

    firestore.collection('weights').doc(id).delete().then(() => {
      dispatch({type: 'DELETE_WEIGHT', id});
    }).catch((err) => {
      dispatch({type: 'DELETE_WEIGHT_ERROR', err});
    })
  }
};