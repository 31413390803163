import React, { Component } from 'react'
import { connect } from 'react-redux'
import { addWeight } from '../../store/actions/weightActions'
import { Redirect } from 'react-router-dom'
// import { Button } from '@material-ui/core'
// import { Container } from '@material-ui/core'

class NewWeight extends Component {
  state = {
    weight: '',
		bodyFatPerct: '',
		totalBodyWater: '',
		musclePerct: '',
		boneMass: '',
    note: ''
  }

  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value
    })
  }
  handleSubmit = (e) => {
    e.preventDefault();
    console.log(this.state);
    this.props.addWeight(this.state);
    this.props.history.push('/');
  }

  render() {
    const { auth } = this.props;
    if(!auth.uid) return <Redirect to='/signin' />

    return (
      <div className="container">
        <form onSubmit={this.handleSubmit} className="white">
          <h5 className="center grey-text text-darken-3">Add New Weight</h5>
          <div className="row">
            {/* <div className="input-field col s12 m6">
              <label htmlFor="title">Date</label>
              <input type="text" className="validate" onChange={this.handleChange} required/>
            </div> */}
            <div className="input-field col s12 m6">
              <label htmlFor="title">Weight</label>
              <input type="number" step="any" min="0" id="weight" className="validate" onChange={this.handleChange} required/>
            </div>
          </div>
          <div className="row">
            <div className="input-field col s12 m6">
              <label htmlFor="bodyFatPerct">Body Fat %</label>
              <input type="number" step="any" min="0" id="bodyFatPerct" className="validate" onChange={this.handleChange} required/>
            </div>
            <div className="input-field col s12 m6">
              <label htmlFor="totalBodyWater">Total Body Water</label>
              <input type="number" step="any" min="0" id="totalBodyWater" className="validate" onChange={this.handleChange} required/>
            </div>
          </div>
          <div className="row">
            <div className="input-field col s12 m6">
              <label htmlFor="musclePerct">Muscle %</label>
              <input type="number" step="any" min="0" id="musclePerct" className="validate" onChange={this.handleChange} required/>
            </div>
            <div className="input-field col s12 m6">
              <label htmlFor="boneMass">Bone Mass</label>
              <input type="number" step="any" min="0" id="boneMass" className="validate" onChange={this.handleChange} required/>
            </div>
          </div>
          <div className="row">
            <div className="input-field col s12 m12">
              <label htmlFor="note">Note</label>
              <textarea id="note" onChange={this.handleChange} className="materialize-textarea validate"></textarea>
            </div>
          </div>
          <div className="row center">
            <div className="input-fieldcol s12 m12">
              <button className="btn pink">Add Weight</button>
              {/* <Button variant="contained" color="primary">
                Add Weight
              </Button> */}
            </div>
          </div>
        </form>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    addWeight: (weight) => dispatch(addWeight(weight))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewWeight)
