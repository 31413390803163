const initState = {
  weight: [{}]
}

const weightReducer = (state = initState, action) => {
  switch(action.type) {
    case 'ADD_NEW_WEIGHT':
      console.log('added new weight', action.weight);
      return state;
    case 'ADD_NEW_WEIGHT_ERROR':
      console.log('add new weight error', action.err);
      return state;
    case 'DELETE_WEIGHT':
      console.log('deleted weight', action.id);
      return state;
    case 'DELETE_WEIGHT_ERROR':
      console.log('add new weight error', action.err);
      return state;
    default:
      return state;
  } 
}

export default weightReducer